import React from "react"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from 'gatsby'
import Landing from '../route/Landing/index'
export default function Home({ location, blok }) {

  return (
    <StaticQuery
      query={graphql`{
      posts: allStoryblokEntry(filter: {full_slug: {regex: "/en/blog/(.)+/"}}, limit: 3, sort: { fields: first_published_at, order: DESC}) {
        edges {
          node {
            full_slug
            name
            field_image_string
            field_intro_string
            published_at
            first_published_at
          }
        }
      }}`
      }
      render={data => (
        <Layout location={location} t="default">
          <Landing posts={data} t="default" />
        </Layout>
      )}
    />
  )
}